<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="group">
      <GroupHeader :group="group" />
      <v-card-title>
        <v-spacer/>
        <v-btn v-if="group.is_owner" color="accent" :to="{name:'profileGroupsEdit', params: {id: group.id}}"><v-icon small class="fal fa-pencil mr-2" /> Edit group</v-btn>
      </v-card-title>

      <div v-if="group">
        <v-card-title class="subtitle pt-4">{{ (group.type || Group) | titleize}} details</v-card-title>
        <v-card-text>      
          <vue-markdown class="markdown" :html="false">{{group.description }}</vue-markdown>

          <p v-if="group && !group.public">
            <v-icon small >fa-lock</v-icon> This group is private and only accessible for members.
          </p>
          <p v-if="group && group.members && group.members.length > 0">
            <v-icon small >fa-users</v-icon> <strong>{{group.members.length}} sodisp user(s)</strong> have joined this group.
          </p>
        </v-card-text>

        <v-card-title class="subtitle">Events connected by this group</v-card-title>
        <v-card-text v-if="group && (!group.events || group.events == 0)">
          <p class="mb-0">This group hasn't connected with any events.</p>
        </v-card-text>      
        <EventGrid v-if="group" minimal :routeResolver="resolveEventGroupRoute" :items="group.events" class="mx-4 mb-4"></EventGrid>
        <br/>
      </div>      
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import VueMarkdown from '@/components/VueMarkdown.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import EventGrid from "@/components/EventGrid.vue";

export default {
  name: "ProfileGroupsView",
  components: {
    EventGrid,
    GroupHeader,
    VueMarkdown,
  },
  props: {
  },
  data() {
    return {
      breadcrumbItems: [
        { text: 'Profile', exact: true, to: { name: 'profile' } },
        { text: 'Groups', exact: true, to: { name: 'profileGroups' } },
        { text: 'View group', disabled: true },
      ],
      group: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await profileService.getGroup(id);
      this.group = response.data;

      if (this.group.type == 'ORGANIZATION') {
        this.$router.push({name: 'organization', params: {id: this.group.id }});
      }
    },

    resolveEventGroupRoute(eventId) {
      return {name: 'profileGroupsEvent', params: {id: this.group.id, eventId: eventId}}
    },

    async getProfile() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },
  },
  computed: {

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

